import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import YouTubeIcon from '@material-ui/icons/YouTube'
import greyLog from '../assets/static/images/logo-bicc-gray.png'
import Grid from '@material-ui/core/Grid';
import {useHistory} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: '#0a1b3e',
    padding: theme.spacing(6),
    color: theme.palette.background.paper,
  },
  root: {
      margin: theme.spacing(1),
      width: '25ch',
  },
  preFooter: {
    backgroundColor: '#f6f6f6;',
    padding: theme.spacing(4),
    color: '#1d1d1d',
    marginTop: '80px',
  },
}));

function Copyright() {
  return (
    <Typography variant="body2" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://beeframeworks.co.uk/">
        Bee Frameworks
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

function Footer() {
  const classes = useStyles();
  const facebookLink = 'https://www.facebook.com/groups/235289024376521/?ref=share'
  const twitterLink = 'https://mobile.twitter.com/leedsbicc'
  const instaLink = 'https://instagram.com/leeds_bicc?utm_medium=copy_link'
  const youtubeLink = 'https://www.youtube.com/channel/UC0fotFPzL6sS66qYeELp3Ng/videos'


  const handleMenuClose = (social) => {
    switch(social) {
      case 'facebook':
        window.open(facebookLink, '_blank')
        break;
      case 'twitter':
        window.open(twitterLink, '_blank')
        break;
      case 'instagram':
        window.open(instaLink, '_blank')
        break;
      case 'youtube':
        window.open(youtubeLink, '_blank')
      default:
        window.open('https://www.facebook.com', '_blank')
    }
  };

  let history = useHistory();

  function handleNavigate() {
    history.push({
      pathname: '/services',
    });
  }

  return (
    <div>
      <footer className={classes.preFooter}>
        <Typography variant="h6" align="center" gutterBottom>
          Connect with us
        </Typography>
        <div style={{textAlign: 'center', margin: '20px'}}>
          <FacebookIcon onClick={() => handleMenuClose('facebook')}
                        style={{ margin: '15px', width: '50px', height: '50px', cursor: 'pointer'}}/>
          <TwitterIcon  onClick={() => handleMenuClose('twitter')}
                        style={{ margin: '15px', width: '50px', height: '50px', cursor: 'pointer'}}/>
          <InstagramIcon  onClick={() => handleMenuClose('instagram')}
                        style={{ margin: '15px', width: '50px', height: '50px', cursor: 'pointer'}}/>
          <YouTubeIcon  onClick={() => handleMenuClose('youtube')}
                          style={{ margin: '15px', width: '50px', height: '50px', cursor: 'pointer'}}/>
        </div>

      </footer>
      {/* Footer */}
      <footer className={classes.footer}>
        <Grid container spacing={2} justify="center">
          <Grid item xs={12} sm={6}>
            <div style={{color: 'white', textAlign: 'center'}}>
              <div
                style={{backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  height: '150px',
                  backgroundImage: `url(${greyLog})`,
                  backgroundSize: 'contain',
                  marginBottom: '15px'
                }}
              >
              </div>
              <Typography variant='h6' style={{color: 'white', fontWeight: 700, fontFamily: 'Bree Serif'}}>
                Leeds BICC
              </Typography>
              <Typography variant='h8' style={{color: 'white', fontSize: '0.8rem'}}>
                Cottingley Community Centre<br/>
                115 Cottingley Approach <br/>
                LS11 0HJ <br/>
                Leeds <br/>
                United Kingdom
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div style={{color: 'white', textAlign: 'center'}}>
              <Typography variant='h6' style={{color: '#999', fontWeight: 700, paddingBottom: '20px'}}>
                Social Media
              </Typography>
              <div style={{fontSize: '0.8rem'}}>
                {/*<Link color="inherit" href="https://exukonline.com">*/}
                {/*  LinkedIn*/}
                {/*</Link> */}
                {/*<br/><br/>*/}
                <Link color="inherit" href={facebookLink} target="_blank">
                  Facebook
                </Link><br/>
                <Link color="inherit" href={twitterLink} target="_blank">
                  Twitter
                </Link><br/>
                <Link color="inherit" href={instaLink} target="_blank">
                  Instagram
                </Link><br/>
                <Link color="inherit" href={youtubeLink} target="_blank">
                  YouTube
                </Link>

                <br/><br/>
              </div>
            </div>
          </Grid>
        </Grid>
        <hr style={{marginBottom: '20px', border: '1px solid #002d40', marginTop: '25px'}}/>
        <Copyright />
      </footer>
      {/* End footer */}
    </div>
  )
}

export default Footer;