import React, { Component } from "react";
import PrimarySearchAppBar from './PrimarySearchAppBar'
import { Switch, Route } from "react-router-dom";
import Home from './Home'
import About from './About'
import Services from './Services'
import PastoralTeam from './PastoralTeam'
import Contact from './Contact'
import Footer from './Footer'
import Pricing from './Pricing'
import Branches from "./Branches";
import CookieConsent from "react-cookie-consent";

class Main extends Component {
  render() {
    return (
      <div>

        <PrimarySearchAppBar />

        <Switch>
          <Route path='/' exact component={Home} />
          <Route path='/about-us' exact component={About} />
          <Route path='/charitable-causes' exact component={Services} />
          <Route path='/contact' exact component={Contact} />
          <Route path='/pastoral-team' exact component={PastoralTeam} />
          <Route path='/pricing' exact component={Pricing} />
          <Route path='/branches' exact component={Branches} />
        </Switch>

        <Footer />
        <CookieConsent style={{ background: 'rgba(0, 0, 0, 0.5)' }} buttonStyle={{ backgroundColor: '#B93703', color: 'white'}}>This website uses cookies to enhance the user experience.</CookieConsent>
      </div>
    )
  }
}

export default Main;