export const headSection = {
  logo: 'here',
  topTitle: 'Welcome to BICC',
  topSubTitle: '- Leeds'
}

export const cardsData = [
  {'title': 'Community Outreach', 'price': 250,
    'remote_image': 'https://idaily-img.s3.eu-west-2.amazonaws.com/n-27u-23-01-22-19-16-58-exam-revision.jpg',
    'desc': 'Giving a helping hand to those that need it the most', 'id': 2},
  {'title': 'Events', 'price': 100,
  'remote_image': 'https://idaily-img.s3.eu-west-2.amazonaws.com/n-27u-23-01-22-19-19-35-exam-revision.jpg',
  'desc': 'Training young people in basic key life-skills; run skills workshops and training to equip disadvantaged or vulnerable people to improve their circumstances;', 'id': 1}
  ];

export const howWeHelp = {
  howText: 'We are passionate about helping clients on how they can utilise their information technology (IT)' +
    ' to optimally achieve their business goals. Let our consultants soothe your IT headache.'
}