export const data = {
  whoWeAre: "The Brethren in Christ Church is a Christian evangelical \n" +
      "church. With a growing presence in the United Kingdom, it has its original roots in North America as part of the " +
      "Mennonites. Part of it’s doctrinal beliefs are feet washing, the dedication of\n" +
      "children, praying for the sick, laying on of hands and baptism by emission.\n" +
      "\n" +
      "\n",
  aboutUs: "Leeds Brethren in Christ presently meet and fellowship at the Cottingley Community Centre in Leeds every Sunday. \n" +
      "Church services include prayer and worship, the observance of praise and the reading from the Bible – preaching " +
      "and teaching is a core part of the church service. \n" +
      "These services are open to all members of the public irrespective of creed, colour nor gender. No fee is charged for attendance.\n" +
      "\n" +
      "All who attend receive spiritual support, personal and religious instruction, mental and emotional benefit."
}