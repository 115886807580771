import React, {useEffect, useRef} from 'react';
import {Breadcrumb, BreadcrumbItem} from "reactstrap";
import {Link} from "react-router-dom";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import wallPaper from "../assets/static/images/pastoral-team-cover.png";
// import wallPaper from "../assets/static/images/OMtmkr.jpg";
import liveinCare from "../assets/static/images/rev-leodah-gray.jpg"
import supportedLiving from '../assets/static/images/rev-ncube-gray.jpg'
import evangelist from '../assets/static/images/evangelist.png'
import decon from '../assets/static/images/decon-gray.jpg'
import {Helmet} from "react-helmet";
import CssBaseline from "@material-ui/core/CssBaseline";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import { useHistory } from 'react-router-dom';
import { useLocation } from "react-router-dom";


import {sectionOne, sectionTwo} from "./configs/ServicesData";


const useStyles = makeStyles((theme) => ({
    icon: {
        marginRight: theme.spacing(2),
    },
    heroContent: {
        padding: theme.spacing(14, 0, 6),
        backgroundImage: `url(${wallPaper})`,
        backgroundColor: 'rgba(0, 0, 0, 0.13)',
        backgroundBlendMode: 'darken',
        backgroundSize: 'cover'
    },
    heroButtons: {
        marginTop: theme.spacing(4),
    },
    cardGrid: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
    },
    cardTitle: {
        fontWeight: 'bold',
        paddingBottom: '25px'
    },
    cardContentText: {
        paddingBottom: '20px',
        fontWeight: 'bold'
    },
    card: {
        display: 'flex',
        flexDirection: 'column',
        boxShadow: '0 12px 24px 0 rgba(0,0,0,0.08)'
    },
    cardMedia: {
        paddingTop: '56.25%', // 16:9
        marginBottom: '15px',
    },
    cardContent: {
        flexGrow: 1,
    },
    root: {
        paddingBottom: '80px',
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    sections: {
        textAlign: 'left'
    },
    services: {
        marginTop: '48px'
    },
    sectionTitles: {
        fontWeight: 'bolder',
        color: 'black',
        paddingBottom: '30px',
        paddingTop: '64px'
    },
    footer: {
        backgroundColor: '#2f3030',
        padding: theme.spacing(6),
        color: theme.palette.background.paper,
    },
    preFooter: {
        backgroundColor: '#dfe0e0',
        padding: theme.spacing(4),
        color: theme.palette.background.paper,
    },
}));

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)

export default function PastoralTeam(props) {

    const classes = useStyles();

    const myRef = useRef(null)
    const executeScroll = () => scrollToRef(myRef)

    const webRef = useRef(null)
    const executeWebScroll = () => scrollToRef(webRef)

    const location = useLocation();

    useEffect(() => {
        const section = location.section
        if (section === 'IT Support') {
            executeScroll()
        }
        else if (section === 'Web Design' || section === 'Digital Marketing') {
            executeWebScroll()
        } else {
            window.scrollTo(0, 0)
        }
    }, [location])


    const handleButtonClick = () => {

        handleNavigate()

    };

    let history = useHistory();

    function handleNavigate() {
        history.push('/contact')
    }

    return (
        <React.Fragment>
            <Helmet>
                <meta name="description"
                      content="Leeds BICC - Proudly Serving The Lord" />
                <title>Leeds Brethren In Christ Churh</title>
            </Helmet>
            <CssBaseline />
            <main>
                {/* Hero unit */}
                <div className={classes.heroContent}>
                    <Breadcrumb>
                        <BreadcrumbItem><Link to="/">Home</Link></BreadcrumbItem>
                        <BreadcrumbItem active>Pastoral Team</BreadcrumbItem>
                    </Breadcrumb>
                    <Container maxWidth="sm">
                        <Typography
                            component="h1"
                            variant="h4"
                            align="center"
                            // color="textPrimary"
                            style={{fontWeight: 'bold', color: 'white'}}
                            gutterBottom>
                            <span className="page-header">Pastoral Team</span>
                        </Typography>
                    </Container>
                </div>
            </main>

            <Container ref={webRef} maxWidth="md" className={classes.sections}>
                <div className={classes.services}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <Card className={classes.card} >
                                <CardMedia
                                    className={classes.cardMedia}
                                    image={supportedLiving}
                                    title="Web Design at Bee Frameworks"
                                />
                                <CardContent className={classes.cardContent}>
                                    <Typography gutterBottom variant="h6" component="h2" className={classes.cardTitle}>
                                        {sectionOne.cardOneTitle}
                                    </Typography>
                                    {sectionOne.cardOneDesc.map((text, index) => (
                                        <div className={classes.cardContentText}>
                                            <Typography
                                                className={index === 0 || text.startsWith('“') ? 'title-text': 'default-text'}
                                            >
                                                {text}
                                            </Typography>
                                        </div>
                                    ))
                                    }
                                </CardContent>
                                <CardActions style={{marginBottom: '20px', alignItems: 'center', justifyContent: 'center'}}>
                                    <Button size="small" variant="contained" color="secondary" onClick={() => handleButtonClick()}>
                                        Contact
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Card className={classes.card} >
                                <CardMedia
                                    className={classes.cardMedia}
                                    image={liveinCare}
                                    title="Digital Marketing at Bee Frameworks"
                                />
                                <CardContent className={classes.cardContent}>
                                    <Typography gutterBottom variant="h6" component="h2" className={classes.cardTitle}>
                                        {sectionOne.cardTwoTitle}
                                    </Typography>
                                    {sectionOne.cardTwoDesc.map((text, index) => (
                                        <div className={classes.cardContentText}>
                                            <Typography
                                            className={index === 0 || text.startsWith('“') ? 'title-text': 'default-text'}
                                            >
                                                {text}
                                            </Typography>
                                        </div>
                                    ))
                                    }
                                </CardContent>
                                <CardActions style={{marginBottom: '20px', alignItems: 'center', justifyContent: 'center'}}>
                                    <Button size="small" variant="contained" color="secondary" onClick={() => handleButtonClick()}>
                                        Contact
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    </Grid>
                </div>
                <div className={classes.services}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} style={{display: 'None'}}>
                            <Card className={classes.card} >
                                <CardMedia
                                    className={classes.cardMedia}
                                    image={evangelist}
                                    title="Digital Marketing at Bee Frameworks"
                                />
                                <CardContent className={classes.cardContent}>
                                    <Typography gutterBottom variant="h6" component="h2" className={classes.cardTitle}>
                                        {sectionTwo.cardOneTitle}
                                    </Typography>
                                    {sectionTwo.cardOneDesc.map((text) => (
                                        <div className={classes.cardContentText}>
                                            <Typography>
                                                {text}
                                            </Typography>
                                        </div>
                                    ))
                                    }
                                </CardContent>
                                <CardActions style={{marginBottom: '20px', alignItems: 'center', justifyContent: 'center'}}>
                                    <Button size="small" variant="contained" color="secondary" onClick={() => handleButtonClick()}>
                                        Contact
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Card className={classes.card} >
                                <CardMedia
                                    className={classes.cardMedia}
                                    image={decon}
                                    title="Pastoral Team"
                                />
                                <CardContent className={classes.cardContent}>
                                    <Typography gutterBottom variant="h6" component="h2" className={classes.cardTitle}>
                                        {sectionTwo.cardTwoTitle}
                                    </Typography>
                                    {sectionTwo.cardTwoDesc.map((text, index) => (
                                        <div className={classes.cardContentText}>
                                            <Typography
                                                className={index === 0 || text.startsWith('“') ? 'title-text': 'default-text'}
                                            >
                                                {text}
                                            </Typography>
                                        </div>
                                    ))
                                    }
                                </CardContent>
                                <CardActions style={{marginBottom: '20px', alignItems: 'center', justifyContent: 'center'}}>
                                    <Button size="small" variant="contained" color="secondary" onClick={() => handleButtonClick()}>
                                        Contact
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    </Grid>
                </div>
            </Container>
        </React.Fragment>
    );
}