import React from "react";


export const sectionOne = {
    topTitle: 'Charitable Causes',
    topSubTitle: 'The Leeds Brethren in Christ Church is also a registered charity with the Charities Commission of ' +
        'England and Wales. As part of its charity obligations, the Church regularly undertakes a number of charitable ' +
        'events in for the youth and elderly alike. Some of the charitable work includes:',
    cardOneTitle: 'Charity',
    cardOneDesc: [
        "Providing training, advocacy, health advice and education;\n",
        "Training young people in basic key life-skills; run skills workshops and training to equip disadvantaged or vulnerable people to improve their circumstances;\n",
        " Raising funds to support individuals facing hardship;\n",
        "Guidance, mentoring and role models for young people;\n",
        "Organising and holding seminars/workshops, conferences around developmental issues.\n",
        "Offering advice, information and guidance in education and support;\n",
        "Providing generalist advice, information and guidance to help integration of minority communities in the UK;\n",
        "Organising training in financial literacy and basic skills to hard-to-reach groups and communities;\n",
        "Offering Christian counselling and advice for persons experiencing bereavement or loss;\n",
        "Organising and holding Christian and social events bringing people of different backgrounds together;",
    ],
    cardOneDescPt2: 'Because of our pay monthly pricing' +
        '    \'model, it means our services are affordable without compromising on quality.',
    cardTwoTitle: 'Community Outreach',
    cardTwoDesc: [
        "Content to be provided"
    ]
}

export const sectionTwo = {
    topTitle: 'Decon & Evangilist',
    topSubTitle: 'We provide cost-effective IT support services, we have experience in implementing and supporting\n' +
        '          Microsoft-based IT infrastructures. We build and maintain software by leveraging cutting edge technology.\n' +
        '          In short, we provide an end-to-end support for all your software needs.',
    cardOneTitle: 'Evangelist Nkosi and Sisanda Mlilo',
    cardOneDesc: ['Children of God'],
    cardTwoTitle: 'Deacon Samuel Letang and Saneliso Mnyama',
    cardTwoDesc: [
        'Servants of our Lord Jesus Christ and the Brethren in Christ Church.',
        'Salvation is found in no one else, for there is no other name under heaven given to mankind by which we must be saved.',
        '- Acts 4:12 (NIV) '
    ]
}