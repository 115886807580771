import React, { useState, useEffect } from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import {Link} from "react-router-dom";
import Container from '@material-ui/core/Container';
import Grid from "@material-ui/core/Grid";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon/ListItemIcon";
import InfoIcon from '@material-ui/icons/Info';
import MapIcon from '@material-ui/icons/Map';
import MailIcon from '@material-ui/icons/Mail';
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import CircularProgress from '@material-ui/core/CircularProgress';
import {Helmet} from "react-helmet";
import CssBaseline from "@material-ui/core/CssBaseline";
import {makeStyles} from "@material-ui/core/styles";
import wallPaper from "../assets/static/images/maps-cover-image.jpg";
import UKMap from "../assets/static/images/map-uk-v3.png"
import MediaCard from "./MediaCard";
import LinearProgress from '@material-ui/core/LinearProgress';

const branchesData = [
  {
    "imageURL": "https://idaily-img.s3.eu-west-2.amazonaws.com/m-6u-09-10-22-12-44-25-exam-revision.jpeg",
    "name": "SOUTH-END-ON-SEA",
    "address": "2 High street \n South end on sea \n SEO2 1AV",
    "site": "www.bicc.org"
  },
  {
    "imageURL": "https://idaily-img.s3.eu-west-2.amazonaws.com/m-6u-09-10-22-12-47-19-exam-revision.jpeg",
    "name": "BASILDON",
    "address": "2 High street",
    "site": "www.bicc.org"
  },
  {
    "imageURL": "https://idaily-img.s3.eu-west-2.amazonaws.com/m-6u-09-10-22-12-46-00-exam-revision.jpeg",
    "name": "STOKE-ON-TRENT",
    "address": "2 High street",
    "site": "www.bicc.org"
  },
  {
    "imageURL": "https://idaily-img.s3.eu-west-2.amazonaws.com/m-6u-09-10-22-12-48-14-exam-revision.jpeg",
    "name": "FOREST GATE",
    "address": "2 High street",
    "site": "www.bicc.org"
  },
  {
    "imageURL": "https://idaily-img.s3.eu-west-2.amazonaws.com/m-6u-09-10-22-12-48-51-exam-revision.jpeg",
    "name": "MANCHESTER",
    "address": "2 High street",
    "site": "www.bicc.org"
  }
]

const useStyles = makeStyles((theme) => ({
  heroContent: {
    padding: theme.spacing(14, 0, 6),
    backgroundImage: `url(${wallPaper})`,
    backgroundColor: 'rgba(0, 0, 0, 0.13)',
    backgroundBlendMode: 'darken',
    backgroundSize: 'cover'
  }
}));

export default function Branches(props) {

  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <React.Fragment>
      <Helmet>
        <meta name="description"
              content="Leeds BICC - Proudly Serving The Lord" />
        <title>Leeds Brethren In Christ Church</title>
      </Helmet>
      <CssBaseline />
      <main>
        {/* Hero unit */}
        <div className={classes.heroContent}>
          <Breadcrumb>
            <BreadcrumbItem><Link to="/">Home</Link></BreadcrumbItem>
            <BreadcrumbItem active>Branches</BreadcrumbItem>
          </Breadcrumb>
          <Container maxWidth="sm">
            <Typography
              component="h1"
              variant="h4"
              align="center"
              // color="textPrimary"
              style={{fontWeight: 'bold', color: 'white'}}
              gutterBottom>
              <span className="page-header"> Branches</span>
            </Typography>
          </Container>
        </div>
      </main>
      <div className='container'>
        <div>
          {/*{isLoaded ? null :*/}
          {/*  <Container maxWidth="md">*/}
          {/*    <div>*/}
          {/*      <LinearProgress />*/}
          {/*    </div>*/}
          {/*  </Container>*/}
          {/*}*/}

          <Container maxWidth="md">
            <div style={{paddingTop: '30px', paddingBottom: '50px'}}>
              <List>
                <Grid container spacing={3}>

                  <Grid item xs={12} sm={6}>
                    <ListItem>
                      <ListItemIcon>
                        <InfoIcon />
                      </ListItemIcon>
                      <ListItemText
                        disableTypography
                        primary={<Typography variant="h6" style={{ fontWeight: 800}}>
                          Our Branches</Typography>}
                      />
                    </ListItem>
                    {branchesData.map((d, i) => {
                      return (
                        <MediaCard
                          d={d}
                          i={i}
                        />
                      );
                    })}
                  </Grid>
                  <Grid item xs={12} sm={6}

                  >
                    <ListItem>
                      <ListItemIcon>
                        <MapIcon />
                      </ListItemIcon>
                      <ListItemText
                        disableTypography
                        primary={<Typography variant="h6" style={{ fontWeight: 800}}>
                          Map</Typography>}
                      />
                    </ListItem>
                    <div style={{
                      background: `url(${UKMap})`,
                      backgroundSize:'cover',
                      backgroundRepeat: 'no-repeat',
                      borderRadius: '4px',
                      height: '600px',
                      marginTop: '24px'
                    }}>

                    </div>
                  </Grid>
                </Grid>
              </List>
            </div>
          </Container>
        </div>
      </div>
    </React.Fragment>
  );
}