import React from "react";


export const sectionOne = {
  topTitle: 'Senior Pastors',
  topSubTitle: 'Senior Pastors',
  cardOneTitle: 'Senior Pastor',
  cardOneDesc: [
    "Reverend Douglas & Mrs Linda Ncube",
    "Reverend Ncube is a married \n" +
    "father of three, with two boys and a\n" +
    "girl. He worshiped at Lobengula BICC (Zimbabwe) as from\n" +
    "1982, was baptised at Pumula BICC in 1984; subsequently serving as\n" +
    "a church Deacon at Lobengula BICC as from 1986. He is presently\n" +
    "serving the Lord as the Senior Pastor for Leeds BICC and has completed a BTH Mission (Hons) Theology at Springdale College.\n",

    "“My focus is to see Jesus in Leeds as central to every aspect of the Christian faith. The one who in his death on the cross and resurrection has won us salvation, redemption and ministry. Jesus as the instigator and giver fulfils everything called Christian.”\n" +
    "﻿\n",
    "Rev D. Ncube\n" ,
    "- Psalms 27 verse 1"
  ],
  cardOneDescPt2: 'Because of our pay monthly pricing' +
    '    \'model, it means our services are affordable without compromising on quality.',
  cardTwoTitle: 'Assistant Pastors',
  cardTwoDesc: ["Reverend Timothy and Mrs Lovey Leodah Masuku",
    "I met and received the Lord as my personal saviour on the 25th  of September 1975 at the Mpopoma Brethren in " +
    "Christ Church in Zimbabwe. Since then, I have served him in various capacities, including as Pastor, " +
    "Deacon, Sunday School Superintendent and Youth Leader.",
    "I am a strong believer in the Apostolic Creed, i.e. believing in the death and resurrection of our " +
    "Lord and Saviour Jesus Christ. I also believe the coming of the Lord is imminent and we all have a duty to " +
    "spread the Word as was stated in the great commission;",
    "“Go ye therefore, and teach all nations, baptizing them in the name of the Father, and of the Son, " +
    "and of the Holy Ghost: Teaching them to observe all things whatsoever I have commanded you: and, lo, " +
    "I am with you always, even unto the end of the world.”",
    "- Matthew 28:18-20"
  ]
}

export const sectionTwo = {
  topTitle: 'Decon',
  topSubTitle: 'We provide cost-effective IT support services, we have experience in implementing and supporting\n' +
    '          Microsoft-based IT infrastructures. We build and maintain software by leveraging cutting edge technology.\n' +
    '          In short, we provide an end-to-end support for all your software needs.',
  cardOneTitle: 'Evangelist Nkosi and Sisanda Mlilo',
  cardOneDesc: ['Children of God'],
  cardTwoTitle: 'Deacon',
  cardTwoDesc: [
      'Samuel Letang and Mrs Saneliso Mnyama',
    'Servants of our Lord Jesus Christ and the Brethren in Christ Church.',
    '“Salvation is found in no one else, for there is no other name under heaven given to mankind by which we must be saved.“',
    '- Acts 4:12 (NIV) '
  ]
}