import * as React from 'react';
import {Container, Col, Row} from "reactstrap";
import {withRouter} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";

const routeToDetails = (props) => {
  const { history } = props;
  history.push(`/search/${props.d.display_name}/category`);
};

const MediaCard = (props) => {
  return (
    <div>
      <Grid container
            style={{
              boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
              marginTop: 24,
              borderRadius: '4px'
            }}>
        <Grid item xs={4} sm={4}
              style={{background: `url(${props.d.imageURL})`,
                height:'100px',
                backgroundSize:'cover',
                backgroundRepeat: 'no-repeat',
                borderRadius: '4px'
              }}
         />
        {/*</Grid>*/}
        <Grid item xs={8} sm={8}
              style={{
                padding: '8px',
                position: 'relative',
                backgroundColor: 'white',
                borderRadius: '4px'
              }}
        >
          <div style={{fontWeight: 800}}>
            {props.d.name}
          </div>
          <div className="display-linebreak">
            {props.d.address}
          </div>
          <div>
            <Link style={{color: '#B93703'}}>
              website
            </Link>
          </div>
        </Grid>
      </Grid>
      {/*<div onClick={() => routeToDetails(props)} className="col-lg-3 col-md-3 col-3 mt-4"*/}
      {/*     style={{marginBottom: '1.5rem', cursor: 'pointer', height: '120px', width: '150px'}} key={props.i}>*/}
      {/*  <Card>*/}
      {/*    <CardImg*/}
      {/*      top*/}
      {/*      // width="100%"*/}
      {/*      // className="mx-auto car-img img-responsive card-img-home-promo"*/}
      {/*      style={{ height: '120px', width: '150px'}}*/}
      {/*      src={props.d.imageURL}*/}
      {/*      alt="image"*/}
      {/*    />*/}
      {/*    <CardBody style={{ textAlign: 'center', paddingBottom: '0px', paddingLeft: '0.5rem'}}>*/}
      {/*      <div className="car-body-wrapper cards-body">*/}
      {/*        <CardSubtitle style={{ paddingBottom: "10px", color: "black", fontWeight: 700, fontSize: "14px"}}>*/}
      {/*          /!*eslint-disable-next-line*!/*/}
      {/*          <div>*/}
      {/*            {props.d.name}*/}
      {/*          </div>{' '}*/}
      {/*        </CardSubtitle>*/}
      {/*      </div>*/}

      {/*    </CardBody>*/}
      {/*    <div style={{*/}
      {/*      padding: '4px 6px',*/}
      {/*    }}>*/}
      {/*    </div>*/}
      {/*    /!*<div style={{*!/*/}
      {/*    /!*  textAlign: 'center',*!/*/}
      {/*    /!*  color: '#424242',*!/*/}
      {/*    /!*  fontSize: '12px',*!/*/}
      {/*    /!*  fontWeight: 900,*!/*/}
      {/*    /!*  backgroundColor: 'whitesmoke',*!/*/}
      {/*    /!*  padding: '0.5rem 0.5rem',*!/*/}
      {/*    /!* }}>*!/*/}
      {/*    /!*  <span style={{float: 'left'}}> £39.99 USD </span>*!/*/}
      {/*    /!*</div>*!/*/}
      {/*  </Card>*/}
      {/*</div>*/}
    </div>
  );
}

export default withRouter(MediaCard);